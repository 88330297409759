import {useStore} from 'effector-react';
import moment from 'moment-timezone';
import {React, useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {withMask} from 'use-mask-input';
import {$reservationItemsForwardBackwardStore} from '../../Services/trainsService';
import {CustomModal} from '../CustomModal/CustomModal';
import {CustomInput} from '../FormComponents/CustomInput';
import {GenderCheckbox} from '../FormComponents/GenderCheckbox';
import {FioWarningDialog} from './dialogs/FioWarningDialog';
import {DocumentElement} from './DocumentsElement';
import {FioTransliterationDialog} from './dialogs/FioTransliterationDialog';
import {some} from 'lodash';
import {TransliterationRulesCard} from './TransliterationRulesCard';

export const FormElement = ({
                                validationDocumentObjects,
                                setValidationDocumentObjects,
                                discounts,
                                formElement,
                                amountOfElements,
                                formId,
                                reservationDto,
                                setReservationDto,
                                countriesList,
                                preformInfo,
                                carsInfo,
                            }) => {
    const reservationItemsForwardBackward = useStore($reservationItemsForwardBackwardStore);

    const allowedDocuments = carsInfo.forward.AllowedDocumentTypes;
    const isDocumentsIncludesRussianPass = allowedDocuments.AdultTypes.includes('RussianPassport');
    const isDocumentsIncludesBirthCert = allowedDocuments.ChildTypes.includes('BirthCertificate');

    const initialAdultDocument = isDocumentsIncludesRussianPass ? 'RussianPassport' : allowedDocuments.AdultTypes[0];
    const initialChildDocument = isDocumentsIncludesBirthCert ? 'BirthCertificate' : allowedDocuments.ChildTypes[0];

    const [customer, setCustomer] = useState({
        Index: formId,
        DocumentType: formElement.category === 'Adult' ? initialAdultDocument : initialChildDocument,
        DocumentNumber: '',
        CitizenshipCode: '',
        LastName: '',
        FirstName: '',
        MiddleName: '',
        Sex: '',
        Birthday: '',
        Places: {
            forward: formElement.forward,
            backward: formElement.backward,
        },
        isSchoolkid: false,
    });

    let sortedDiscounts = {};
    const tariffs = [
        'Full',
        'Junior',
        'Senior',
        'Pupil',
        'Holiday',
        'Wedding',
        'Family',
        'Kupek',
        'Single',
        'Birthday',
        'BirthdayAccompanying',
        'TksP',
        'TksM',
        'TksI',
        'InternalFamily',
        'Special',
    ];
    sortedDiscounts['forward'] = discounts.forward.filter(item => tariffs.includes(item.DiscountType));
    sortedDiscounts.forward.push({DiscountType: 'Full', Description: 'Полный'});
    sortedDiscounts['backward'] = discounts.forward.filter(item => tariffs.includes(item.DiscountType));
    sortedDiscounts.backward.push({
        DiscountType: 'Full',
        Description: 'Полный',
    });
    const [passenger, setPassenger] = useState({
        Category: formElement.category,
        Phone: '',
        ContactEmailOrPhone: '',
        OrderCustomerIndex: formId,
        Tariff: {forward: 'Full', backward: 'Full'},
        NonRefundableTariffs: {forward: false, backward: false},
    });

    //------------------------------------------------------------------------

    const [isFioWarningDialogOpen, setIsFioWarningDialogOpen] = useState(false);

    const [lastNameCheckbox, setLastNameCheckbox] = useState(false);
    const lastNameCheckboxHandler = () => {
        setLastNameCheckbox(!lastNameCheckbox);
        setCustomerHandler({
            target: {
                id: `${formElement.category}`,
                name: `LastName-${formId}`,
                value: '',
            },
        });
    };

    const [firstNameCheckbox, setFirstNameCheckbox] = useState(false);
    const firstNameCheckboxHandler = () => {
        setFirstNameCheckbox(!firstNameCheckbox);
        setCustomerHandler({
            target: {
                id: `${formElement.category}`,
                name: `FirstName-${formId}`,
                value: '',
            },
        });
    };

    const [middlenameCheckbox, setMiddlenameCheckbox] = useState(false);
    const middleNameCheckboxHandler = () => {
        if (customer.DocumentType === 'RussianForeignPassport') {
            if (middlenameCheckbox) {
                setMiddlenameCheckbox(false);
                setCustomerHandler({
                    target: {
                        id: `${formElement.category}`,
                        name: `MiddleName-${formId}`,
                        value: '',
                    },
                });
            } else {
                setIsFioWarningDialogOpen(true);
            }
        } else {
            setMiddlenameCheckbox(!middlenameCheckbox);
            setCustomerHandler({
                target: {
                    id: `${formElement.category}`,
                    name: `MiddleName-${formId}`,
                    value: '',
                },
            });
        }
    };

    const onFioWarningDialogClose = () => {
        setIsFioWarningDialogOpen(false);
    };

    const onFioWarningDialogConfirm = () => {
        setMiddlenameCheckbox(true);
        setCustomerHandler({
            target: {
                id: 'Adult',
                name: `MiddleName-${formId}`,
                value: '',
            },
        });
        onFioWarningDialogClose();
    };

    //------------------------------------------------------------------------
    const [isTransliterationDialogOpen, setIsTransliterationDialogOpen] = useState(false);

    const openTransliterationDialog = () => setIsTransliterationDialogOpen(true);
    const closeTransliterationDialog = () => setIsTransliterationDialogOpen(false);

    //------------------------------------------------------------------------

    const documentDataTypes = {
        RussianPassport: {
            Описание: 'Общегражданский паспорт',
            regex: /^[1-9]\d{0,9}$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        RussianForeignPassport: {
            Описание: 'Общегражданский заграничный паспорт',
            regex: /^[1-9]\d{8}$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        ForeignPassport: {
            Описание: 'Национальный паспорт',
            regex:
                /^(?![-\s\.])(([\d\-]{1,16})|([a-zA-Z\-]{5,16})|((?=.*[a-zA-Zа-яА-Я\-])(?=.*\d)[a-zA-Zа-яА-Я\d\-]{1,16}))(?<![-\s\.])$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        BirthCertificate: {
            Описание: 'Свидетельство о рождении',
            regex: /^(M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3}))[А-Я][А-Я]\d{6}/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        MilitaryCard: {
            Описание: 'Военный билет военнослужащего и курсантов военных образовательных организаций',
            regex: /^[А-Я]{2}\d{6,7}$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        MilitaryOfficerCard: {
            Описание: 'Удостоверение личности для военнослужащих',
            regex: /^(?=.*\d)[A-Za-z0-9-]{1,15}$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        ReturnToCisCertificate: {
            Описание: 'Свидетельство на возвращение',
            regex: /^\d{1,10}(?:-\d{1,10})*$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        DiplomaticPassport: {
            Описание: 'Дипломатический паспорт',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        ServicePassport: {
            Описание: 'Служебный паспорт',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        SailorPassport: {
            Описание: 'Удостоверение личности моряка',
            regex: /^\d{7}$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        StatelessPersonIdentityCard: {
            Описание: 'Удостоверение личности лица без гражданства',
            regex: /\d+/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        ResidencePermit: {
            Описание: 'Вид на жительство',
            regex: /\d+/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        RussianTemporaryIdentityCard: {
            Описание: 'Временное удостоверение личности',
            regex: /^(?![-\s\.])(?=.*\d)[A-Za-zА-Яа-я0-9-]{1,16}(?<![-\s\.])$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        UssrPassport: {
            Описание: 'Паспорт СССР',
            regex: /^((X{1,3}|X{1,2}V|X{1,2}IX)(I[V]|I{0,3}|V)|^(I[XV]|V?I{1,3})|^V)[А-Я][А-Я](?!100000)([1-9]\d{4}[0-9])$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        MedicalBirthCertificate: {
            Описание: 'Медицинское свидетельство о рождении (для детей не старше 1 мес.)',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        LostPassportCertificate: {
            Описание: 'Справка об утере паспорта',
            regex: /^(?![-\s\.])(?=.*\d)[А-Яа-я0-9-]{1,16}(?<![-\s\.])$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        PrisonReleaseCertificate: {
            Описание: 'Справка об освобождении из мест лишения свободы',
            regex: /^(?![-\s\.])(?=.*\d)[A-Za-zА-Яа-я0-9-]{1,16}(?<![-\s\.])$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        CertificateOfTemporaryAsylum: {
            Описание: 'Свидетельство о предоставлении временного убежища (водный транспорт)',
            regex: /^(?![-\s\.])(?=.*\d)[A-Za-zА-Яа-я0-9-]{1,16}(?<![-\s\.])$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        MilitaryTemporaryCard: {
            Описание: 'Временное удостоверение, выданное взамен военного билета',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        ReserveOfficerMilitaryCard: {
            Описание: 'Военный билет офицера запаса',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        UssrForeignPassport: {
            Описание: 'Загранпаспорт СССР',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        RefugeeIdentity: {
            Описание: 'Удостоверение беженца',
            regex: /^(?![-\s\.])(?=.*\d)[A-Za-zА-Яа-я0-9-]{1,16}(?<![-\s\.])$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        RefugeeCertificate: {
            Описание: 'Свидетельство о предоставлении временного убежища на территории РФ',
            regex: /^(?![-\s\.])(?=.*\d)[A-Za-zА-Яа-я0-9-]{1,16}(?<![-\s\.])$/,
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        RussianTemporaryLivingCertificate: {
            Описание: 'Разрешение на временное проживание в Российской Федерации',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        OfficerCertificate: {
            Описание: 'Удостоверение личности офицера',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        MinistryMarineFleetPassport: {
            Описание: 'Паспорт Минморфлота',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        ForeignBirthCertificate: {
            Описание: 'Иностранное свидетельство о рождении',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
        Other: {
            Описание: 'Иные документы, выдаваемые органами МВД',
            regex: '',
            error: 'Неверный ввод данных',
            fio_requirements: '',
        },
    };

    const ForeignDocuments = [
        'ForeignBirthCertificate',
        'ForeignPassport',
        'AnotherDocumentOfAForeignCitizenRecognizedInTheRussianFederation',
    ];

    const isTransliterationRulesMustBeShown =
        customer.DocumentType === 'RussianForeignPassport' || some(ForeignDocuments, val => val === customer.DocumentType);

    const [inputsValues, setInputsValues] = useState({
        FirstName: {value: '', regex: /^[\sa-zA-Zа-яА-ЯёЁ-]+$/},

        LastName: {value: '', regex: /^[\sa-zA-Zа-яА-ЯёЁ-]+$/},

        MiddleName: {value: '', regex: /^[\sa-zA-Zа-яА-ЯёЁ-]+$/},

        DocumentType: formElement.category === 'Adult' ? 'RussianPassport' : 'BirthCertificate',

        DocumentNumber: '',
    });
    const OnBlurHandler = e => {
        let {name, value} = e.target;
        const splittedName = name.split('-')[0];

        let startIndex = 0;
        let endIndex = value.length - 1;

        while (startIndex < value.length && !value[startIndex].match(/[a-zA-Zа-яА-Я]/)) {
            startIndex++;
        }

        while (endIndex >= 0 && !value[endIndex].match(/[a-zA-Zа-яА-Я]/)) {
            endIndex--;
        }

        if (startIndex > endIndex) {
            value = '';
        }

        value = value.substring(startIndex, endIndex + 1);

        let updatedOptions = {...inputsValues};
        updatedOptions[splittedName].value = value;
        setInputsValues(updatedOptions);
        setCustomer({
            ...customer,
            [splittedName]: value,
        });
    };

    const setCustomerHandler = e => {
        const {name, value} = e.target;
        const splittedName = name.split('-')[0];
        let updatedOptions = {...inputsValues};

        if (splittedName === 'LastName' || splittedName === 'FirstName' || splittedName === 'MiddleName') {
            const regex = updatedOptions[splittedName].regex;
            if (regex.test(value) || value === '') {
                updatedOptions[splittedName].value = value;
                setInputsValues(updatedOptions);
                setCustomer({
                    ...customer,
                    [splittedName]: value,
                });
            }
            return undefined;
        }

        if (splittedName === 'DocumentType') {
            updatedOptions[splittedName] = value;
            setInputsValues(updatedOptions);
            setCustomer({
                ...customer,
                [splittedName]: value,
            });
            return undefined;
        }

        if (splittedName === 'DocumentNumber') {
            const particularDocumentType = updatedOptions.DocumentType;
            const regex = documentDataTypes[particularDocumentType].regex;
            // Эту часть кода не трогать, может я еще подумаю как и что
            // if (regex.test(value) || value === '') {
            //   updatedOptions[splittedName] = value
            //   setInputsValues(updatedOptions);
            //   setCustomer({
            //     ...customer,
            //     [splittedName]: value,
            //   });
            // }
            updatedOptions[splittedName] = value;
            setInputsValues(updatedOptions);
            setCustomer({
                ...customer,
                [splittedName]: value,
            });
        } else {
            setCustomer({
                ...customer,
                [splittedName]: value,
            });
        }
    };

    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const setSchoolkidHandler = () => {
        if (customer.isSchoolkid === false) {
            setCustomer({
                ...customer,
                ['isSchoolkid']: true,
            });
            setModalMessage(
                'При посадке в поезд обязательно наличие справки обучающихся и воспитанников общеобразовательных учреждений очной формы обучения',
            );
            setModal(true);
        } else {
            setCustomer({
                ...customer,
                ['isSchoolkid']: false,
            });
        }
    };

    const categoryList = [
        {
            key: 'Adult',
            name: 'Взрослый',
        },
        {
            key: 'Child',
            name: 'Ребенок, занимающий место',
        },
        {
            key: 'BabyWithoutPlace',
            name: 'Ребенок без места',
        },
    ];

    const ageCalculateDate = (minAge) => {
        let todayDate = new Date();
        todayDate.setTime(todayDate.valueOf() - minAge * 365 * 24 * 60 * 60 * 1000);
        return todayDate.toISOString().split("T")[0];
    };

    const calculateAgeAtDeparture = (birthdate, departureDate) => {
        const birthdateParts = birthdate.split('-');
        const departureDateParts = departureDate.split('-');

        const birthYear = parseInt(birthdateParts[0], 10);
        const birthMonth = parseInt(birthdateParts[1], 10);
        const birthDay = parseInt(birthdateParts[2], 10);

        const departureYear = parseInt(departureDateParts[0], 10);
        const departureMonth = parseInt(departureDateParts[1], 10);
        const departureDay = parseInt(departureDateParts[2], 10);

        let ageAtDeparture = departureYear - birthYear;

        if (departureMonth < birthMonth || (departureMonth === birthMonth && departureDay < birthDay)) {
            ageAtDeparture--;
        }

        return ageAtDeparture;
    };

    const ageLimits = minOrMax => {
        const minAndMaxAgeSetter = (min, max) => {
            if (minOrMax === 'Min') {
                return ageCalculateDate(min);
            } else {
                return ageCalculateDate(max);
            }
        };

        const ageCategorySeparator = (min, max) => {
            if (formElement.category === 'Child') {
                return minAndMaxAgeSetter(10, 0);
            } else {
                return minAndMaxAgeSetter(min, max);
            }
        };

        const isMotherAndBaby =
            preformInfo.filter(
                element => element.forwardCarPlaceType === 'MotherAndBaby' || element.backwardCarPlaceType === 'MotherAndBaby',
            ).length > 0;

        if (isMotherAndBaby) {
            return ageCategorySeparator(1, 0);
        } else {
            if (formElement.carType.toLowerCase() === 'soft') {
                return ageCategorySeparator(10, 0);
            } else {
                return ageCategorySeparator(5, 0);
            }
        }
    };

    const setPassengerHandler = e => {
        const {name, value} = e.target;
        const splittedName = name.split('-')[0];

        setPassenger({
            ...passenger,
            [splittedName]: value,
        });
    };

    const handleSelectChange = event => {
        const options = event.target.options;
        let selectedValue = null;
        let direction = null;
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedValue = options[i].value.split('-')[0];
                direction = options[i].value.split('-')[1];
            }
        }
        let updatedOptions = {...passenger};
        if (event.target.value.split('-')[0] === 'Pupil') {
            setModalMessage(
                'При посадке в поезд обязательно наличие справки обучающихся и воспитанников общеобразовательных учреждений очной формы обучения',
            );
            setModal(true);
        }
        if (direction === 'forward') {
            updatedOptions.Tariff.forward = selectedValue;
            updatedOptions.Tariff.backward = 'Full';
        } else {
            updatedOptions.Tariff.backward = selectedValue;
            updatedOptions.Tariff.forward = 'Full';
        }
        setPassenger(updatedOptions);
    };

    useEffect(() => {
        setReservationDto({
            ...reservationDto,
            Customers: {
                ...reservationDto.Customers,
                [formId]: {...customer, Birthday: customer.Birthday + 'T00:00:00'},
            },
            Passengers: {
                ...reservationDto.Passengers,
                [formId]: passenger,
            },
        });
    }, [customer, passenger]);

    return (
        <div className="passenger-form">
            {categoryList.find(item => item.key === formElement.category)?.name}
            {formElement.category !== 'BabyWithoutPlace' ? (
                <span>
          {formElement.backward ? (
              <>
                  <b>, Туда: </b>Место {formElement.forward},<b> Обратно: </b>Место {formElement.backward}
              </>
          ) : formElement.forward ? (
              <>, Место {formElement.forward}</>
          ) : (
              ''
          )}
        </span>
            ) : (
                ''
            )}

            <CustomModal open={modal} onOpen={setModal} onClose={() => setModal(false)}>
                <h2 style={{color: 'red'}}>Внимание!</h2>
                <h4>{modalMessage}</h4>
            </CustomModal>
            <FioWarningDialog
                isOpen={isFioWarningDialogOpen}
                onConfirm={onFioWarningDialogConfirm}
                onClose={onFioWarningDialogClose}
            />
            <FioTransliterationDialog isOpen={isTransliterationDialogOpen} onClose={closeTransliterationDialog}/>
            <Row className="passenger-form__complete-name">
                <Form.Group as={Col} className="mt-2">
                    <CustomInput
                        disabled={lastNameCheckbox}
                        required={!lastNameCheckbox}
                        label="Фамилия"
                        type="text"
                        name={`LastName-${formId}`}
                        id=""
                        onChange={e => setCustomerHandler(e)}
                        maxLength={40}
                        value={inputsValues.LastName.value}
                        onBlur={e => OnBlurHandler(e)}
                    />
                    <p className="error"></p>
                    <Form.Check
                        checked={lastNameCheckbox}
                        label="В документе отсутствует фамилия"
                        onClick={() => lastNameCheckboxHandler()}
                    />
                </Form.Group>
                <Form.Group as={Col} className="mt-2">
                    <CustomInput
                        disabled={firstNameCheckbox}
                        required={!firstNameCheckbox}
                        label="Имя"
                        type="text"
                        name={`FirstName-${formId}`}
                        id=""
                        onChange={e => setCustomerHandler(e)}
                        maxLength={20}
                        value={inputsValues.FirstName.value}
                        onBlur={e => OnBlurHandler(e)}
                    />
                    <p className="error"></p>
                    <Form.Check
                        checked={firstNameCheckbox}
                        label="В документе отсутствует имя"
                        onClick={() => firstNameCheckboxHandler()}
                    />
                </Form.Group>
                <Form.Group as={Col} className="mt-2">
                    <CustomInput
                        disabled={middlenameCheckbox}
                        required={!middlenameCheckbox}
                        label="Отчество"
                        type="text"
                        name={`MiddleName-${formId}`}
                        id=""
                        onChange={e => setCustomerHandler(e)}
                        maxLength={20}
                        value={inputsValues.MiddleName.value}
                        onBlur={e => OnBlurHandler(e)}
                    />

                    <p className="error"></p>
                    <Form.Check
                        checked={middlenameCheckbox}
                        label={
                            customer.DocumentType === 'RussianForeignPassport'
                                ? 'Отчество на кириллице и латинице отсутствует'
                                : 'В документе отсутствует отчество'
                        }
                        onClick={() => middleNameCheckboxHandler()}
                    />
                </Form.Group>
            </Row>
            {isTransliterationRulesMustBeShown && <TransliterationRulesCard onRulesClick={openTransliterationDialog}/>}

            <Row className="passenger-form__additional-info">
                <Form.Group as={Col} className="mt-2">
                    <Form.Label>Дата рождения</Form.Label>
                    <>
                        <Form.Control
                            required
                            type="date"
                            id=""
                            name={`Birthday-${formId}`}
                            min={formElement.category === 'Adult' ? '1900-01-01' : ageLimits('Min')}
                            max={
                                formElement.category === 'Adult'
                                    ? ageCalculateDate(
                                        customer.DocumentType === 'RussianPassport' || customer.DocumentType === 'UssrPassport' ? 14 : 10,
                                    )
                                    : ageLimits('Max')
                            }
                            onChange={e => setCustomerHandler(e)}
                        />
                    </>
                </Form.Group>
                <Form.Group as={Col} className="mt-2">
                    <Form.Label>Пол</Form.Label>
                    <GenderCheckbox
                        category={formElement.category}
                        forwardGender={formElement.forwardGender}
                        backwardGender={formElement.backwardGender}
                        id="Adult"
                        name={`Sex-${formId}`}
                        onChange={e => setCustomerHandler(e)}
                        required={true}
                    />
                    <p className="error"></p>
                </Form.Group>
                {formElement.category === 'Child' ? (
                    <Form.Group as={Col} sm={12} lg={12}>
                        <Form.Check
                            className="mt-1 mb-2"
                            type="checkbox"
                            label="Этот пассажир является школьником"
                            checked={customer.isSchoolkid === true}
                            onChange={() => setSchoolkidHandler()}
                            readOnly
                        />
                    </Form.Group>
                ) : (
                    ''
                )}
            </Row>
            <DocumentElement
                validationDocumentObjects={validationDocumentObjects}
                setValidatiomDocumentObjects={setValidationDocumentObjects}
                formId={formId}
                category={formElement.category}
                countriesList={countriesList}
                customer={customer}
                setCustomerHandler={setCustomerHandler}
                setCustomer={setCustomer}
                inputValues={inputsValues}
                carsInfo={carsInfo}
            />
            <Row className="mt-4 ">
                {amountOfElements > 1 && formElement.category !== 'BabyWithoutPlace' ? (
                    <Form.Group as={Col} sm={12} lg={4}>
                        <Form.Check
                            className="mt-3"
                            type="checkbox"
                            label="Этот пассажир — покупатель"
                            checked={reservationDto.Buyer === formId}
                            onChange={() => setReservationDto({...reservationDto, Buyer: formId})}
                            readOnly
                        />
                    </Form.Group>
                ) : (
                    ''
                )}
            </Row>

            <Row className="passenger-form__communications mt-2 mb-4">
                <Form.Group as={Col} className="mt-2 passenger-form__phone">
                    <Form.Label>Мобильный телефон</Form.Label>

                    <Form.Control
                        required
                        id=""
                        type="text"
                        name={`Phone-${formId}`}
                        maxLength={26}
                        style={{
                            borderColor: passenger.Phone.length > 21 ? 'red' : '#ced4da',
                        }}
                        ref={withMask('+9{0,25}')}
                        onChange={e => setPassengerHandler(e)}
                    />
                    <p className="error">{passenger.Phone.length > 21 ? 'Поле должно содержать не более 20 символов' : ''}</p>
                </Form.Group>
                <Form.Group as={Col} className="mt-2 passenger-form__email">
                    <CustomInput
                        id=""
                        label="E-mail"
                        type="text"
                        name={`ContactEmailOrPhone-${formId}`}
                        onChange={e => setPassengerHandler(e)}
                    />
                    <p className="error"></p>
                </Form.Group>
                {formElement.category !== 'BabyWithoutPlace' &&
                    formElement.category !== 'Child' &&
                    formElement.backward === null && (
                        <Form.Group as={Col} className="mt-2 passenger-form__rate">
                            <Form.Label>Тариф</Form.Label>
                            <Form.Select defaultValue={'Full-forward'} onChange={e => handleSelectChange(e)}>
                                {(() => {
                                    if (formElement.backward !== null) {
                                        return Object.keys(sortedDiscounts).map((direction, index) => (
                                            <>
                                                {direction === 'forward' ? (
                                                    <option key={index} value="" disabled>
                                                        -- Туда --
                                                    </option>
                                                ) : (
                                                    <option key={index} value="" disabled>
                                                        -- Обратно --
                                                    </option>
                                                )}

                                                {sortedDiscounts[direction].map((value, index) => (
                                                    <option
                                                        key={index}
                                                        value={`${value.DiscountType}-${direction}`}
                                                        disabled={
                                                            value.Description === 'Скидка для школьников' &&
                                                            (calculateAgeAtDeparture(
                                                                    customer.Birthday,
                                                                    reservationItemsForwardBackward.forward.DepartureDate.split('T')[0],
                                                                ) < 10 ||
                                                                calculateAgeAtDeparture(
                                                                    customer.Birthday,
                                                                    reservationItemsForwardBackward.forward.DepartureDate.split('T')[0],
                                                                ) > 18)
                                                        }
                                                    >
                                                        {value.Description}
                                                    </option>
                                                ))}
                                            </>
                                        ));
                                    } else {
                                        return sortedDiscounts.forward.map((value, index) => (
                                            <option
                                                key={index}
                                                value={`${value.DiscountType}-forward`}
                                                disabled={
                                                    value.Description === 'Скидка для школьников' &&
                                                    (calculateAgeAtDeparture(
                                                            customer.Birthday,
                                                            reservationItemsForwardBackward.forward.DepartureDate.split('T')[0],
                                                        ) < 10 ||
                                                        calculateAgeAtDeparture(
                                                            customer.Birthday,
                                                            reservationItemsForwardBackward.forward.DepartureDate.split('T')[0],
                                                        ) > 18)
                                                }
                                            >
                                                {value.Description}
                                            </option>
                                        ));
                                    }
                                })()}
                            </Form.Select>
                        </Form.Group>
                    )}
            </Row>
            <Row className="mt-2 mb-4">
                {formElement.forwardNonReturnableTariff ? (
                    <Form.Group as={Col} sm={12} lg={3}>
                        <Form.Check
                            className="mt-3"
                            type="checkbox"
                            label={
                                formElement.backwardNonReturnableTariff
                                    ? 'Применить невозвратный тариф (Туда)'
                                    : !formElement.backwardNonReturnableTariff && formElement.backward !== null
                                        ? 'Применить невозвратный тариф (Туда)'
                                        : 'Применить невозвратный тариф'
                            }
                            checked={passenger.NonRefundableTariffs.forward}
                            onChange={() =>
                                setPassenger({
                                    ...passenger,
                                    NonRefundableTariffs: {
                                        ...passenger.NonRefundableTariffs,
                                        forward: !passenger.NonRefundableTariffs.forward,
                                    },
                                })
                            }
                            readOnly
                        />
                    </Form.Group>
                ) : (
                    ''
                )}
                {formElement.backwardNonReturnableTariff ? (
                    <Form.Group as={Col} sm={12} lg={3}>
                        <Form.Check
                            className="mt-3"
                            type="checkbox"
                            label="Применить невозвратный тариф (Обратно)"
                            checked={passenger.NonRefundableTariffs.backward}
                            onChange={() =>
                                setPassenger({
                                    ...passenger,
                                    NonRefundableTariffs: {
                                        ...passenger.NonRefundableTariffs,
                                        backward: !passenger.NonRefundableTariffs.backward,
                                    },
                                })
                            }
                            readOnly
                        />
                    </Form.Group>
                ) : (
                    ''
                )}
            </Row>
        </div>
    );
};
